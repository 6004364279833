import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';


declare const gtag: Function;

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css'],
})
export class AppComponent {
	title: string = 'csm-surveys';
	loadingDataImg: boolean = false;

	constructor(private router: Router) {

		/** START : Code to Track Page View using gtag.js */
		this.router.events.pipe(
			filter(event => event instanceof NavigationEnd)
		).subscribe((event: NavigationEnd) => {
			gtag('event', 'page_view', {
				page_path: event.urlAfterRedirects
			})
		})
		/** END : Code to Track Page View  using gtag.js */
	}


}
