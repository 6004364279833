import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
	{ path: ':section/:sfId', loadChildren: '../app/survey/survey.module#SurveyModule' },
	{ path: 'finish', loadChildren: '../app/finish/finish.module#FinishModule' },
	{ path: 'confirmation', loadChildren: '../app/confirmation/confirmation.module#ConfirmationModule' },
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule {}
