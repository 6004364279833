import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Http, Response, Headers, RequestOptions } from '@angular/http';

// Production
// const url = 'https://helios-api-prod.herokuapp.com';

// dev
// const url = 'https://helios-api-dev.herokuapp.com';
// dev local
// const url = 'http://localhost:3000';

const url = environment.urlLink;

@Injectable()
export class HttpService {
	private headers: Headers;
	private options: RequestOptions;
	constructor(private http: Http) {
		this.headers = new Headers();
		this.headers.append('Content-Type', 'application/json');
		this.headers.append('Accept', 'application/json');
		this.options = new RequestOptions({ headers: this.headers });
	}

	saveSurveys(data) {
		// const headers = new Headers({'Content-Type': 'application/json'});
		// const options = new RequestOptions({headers: headers});
		console.log(data);
		const body = data;
		return this.http.post(url + '/saveSurvey', body, this.options).map((res: Response) => res.json());
	}

	checkSurveys(sfId) {
		return this.http.get(url + '/checkSurveys/' + sfId, this.options).map((res: Response) => res.json());
	}
	checkIsExpired(sfId) {
		return this.http.get(url + '/checkIsExpired/' + sfId, this.options).map((res: Response) => res.json());
	}
}
